<template>
  <body style="position: relative;">
    <div id="wrap">
      <section>
        <headerLayout></headerLayout>
      </section>
      <main>
        <section class="sub-visual">
          <div class="innWrap">
            <div class="txt">
              <h2>상담신청</h2>
              <!-- <p>국내 최대 용량의 기업전용망을 통한 고품질 인터넷 전용회선 서비스</p> -->
            </div>
            <div class="location">
              <ul>
                <li><a href="#">SUPPORT</a></li>
                <li><a href="#">서비스 상담</a></li>
                <li><span>상담 신청</span></li>
              </ul>
            </div>
          </div>
        </section>
        <section class="sub-section cnselAplcn">
          <div class="innWrap">
            <h3 class="sLtitle mgb40">
              상담 신청<span class="required">*필수 입력</span>
            </h3>
            <div class="lineBox">
              <div class="form-list">
                <div class="form-Item">
                  <label for="svc-title-01">회사명<span class="required">*</span></label>
                  <div class="fcon multi">
                    <input type="text" id="svc-title-01" placeholder="회사명을 입력해주세요" v-model="partyName" readonly/>
                  </div>
                </div>
                <div class="form-Item">
                  <label for="svc-title-02">사업자번호<span class="required">*</span></label>
                  <div class="fcon">
                    <input type="text" id="svc-title-02" placeholder="사업자번호를 입력해주세요 (-제외)" v-model="partyIdNumber" readonly/>
                  </div>
                </div>
                <div class="form-Item">
                  <label for="svc-title-03">연락처</label>
                  <div class="fcon">
                    <input
                      type="tel"
                      id="svc-title-03"
                      placeholder="연락처를 입력해주세요 (-제외)"
                      v-model="custTelNum"
                      readonly
                    />
                  </div>
                </div>
                <div class="form-Item">
                  <label for="fee-title-04"
                    >이메일<span class="required">*</span></label
                  >
                  <div class="fcon multi">
                    <input type="email" id="" placeholder="이메일" class="w416px" v-model="custEmail1" readonly/>
                    <em>@</em>
                    <input type="email" id="" placeholder="gmail.com" class="w416px" v-model="custEmail2" readonly/>
                    <div class="select w284px">
                      <!-- <span class="placeholder"><span class="fcGy">선택하세요.</span></span> -->
                      <!-- <div class="list">
                        <ul>
                          <li>직접입력</li>
                          <li>naver.com</li>
                          <li>gmail.com</li>
                          <li>daum.net</li>
                        </ul>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="form-Item">
                  <label for="fee-title-05">문의 유형<span class="required">*</span></label>
                  <div class="fcon multi">
                    <div class="select w584px" id="svc-title-05">
                      <span class="placeholder"><span class="fcGy">서비스를 선택하세요</span></span
                      >
                      <div class="list">
                        <ul>
                          <li v-for="item in serviceArr" :key="item.svcCd" @click="selectValue('service', item.svcCd)">
                            {{ item.title }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="select w584px" id="svc-title-05">
                      <span class="placeholder"><span class="fcGy">유형을 선택하세요</span></span>
                      <div class="list">
                        <ul>
                          <li v-for="item in typeList" :key="item.code_cd" @click="selectValue('type', item.code_cd)">
                            {{ item.code_nm }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-Item">
                  <label for="svc-title-06">문의 내용<span class="required">*</span></label>
                  <div class="fcon">
                    <textarea id="svc-title-06" placeholder="상세 문의 내역 기입 (1000자 까지 입력 가능)" v-model="custOpinion" ></textarea>
                  </div>
                </div>
                <div class="form-btom">
                  <div class="checkbox">
                    <input type="checkbox" id="svc-check1" class="hide" v-model="agreeCheck" :value="agreeCheck"/>
                    <label for="svc-check1">개인정보 수집 및 이용에 동의합니다<span class="fcRed">&nbsp;(필수)</span></label>
                  </div>
                  <div>
                    <a
                      class="btn-md02 btn-primary pop-open"
                      @click="postConsult"
                      >신청</a
                    >
                  </div>
                  <div id="pop-info01" class="pop-wrap type02"  v-bind:class="popupFlag ? 'show' : 'hide'">
                    <div class="pop-inner pop-sm">
                      <div class="pop-tit agc">
                        신청이 완료되었습니다
                        <br />
                        <p
                          style="color:black; font-size: 16px; font-weight: 400; margin-top:15px;"
                        >
                          전문 상담사가 신청 내용 확인 후<br />입력해주신
                          이메일로 답변해 드리겠습니다
                        </p>
                      </div>
                      <a
                        class="btn-sm btn-primary pop-close01"
                        @click="$router.push('/goMain000')"
                        >확인</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <section>
        <footerLayout></footerLayout>
      </section>
    </div>
  </body>
</template>
<script>
import { gigaofficeService } from "Api";
import HeaderLayout from "./common/Header.vue";
import FooterLayout from "./common/Footer.vue";

export default {
  components: {
    HeaderLayout,
    FooterLayout
  },
  data() {
    return {
      serviceArr: [
        { svcCd: "70", title: "기업인터넷" },
        { svcCd: "31", title: "GiGA office" },
        { svcCd: "80", title: "Kornet" },
        { svcCd: "90", title: "VPN" },
        { svcCd: "10", title: "Flexline" }
      ],
      //문의 유형
      typeList: '',
      custEmail: '',
      custEmail1: '',
      custEmail2: '',
      custTelNum: '',
      partyName: '',
      svcTypeCd: '',
      custOpinion: '',
      opnoinTypeCd: '',
      agreeCheck: false,
      popupFlag: false,
      partyIdNumber:'',
      partyIdNumber1:'',
      partyIdNumber2:'',
      mobileNumber:'',
      phoneNumber:'',
    };
  },
  created: function() {},
  mounted: async function() {
    await this.getComCodeList()
    await this.getOwnerContactInfoByTokenId();
    // var target = document.querySelectorAll('.pop-open');
    // var btnPopClose01 = document.querySelectorAll('.pop-close01');
    // var btnPopClose02 = document.querySelectorAll('.pop-close02');
    // var targetID;
    // // 팝업 열기
    // for(var i = 0; i < target.length; i++){
    // 		target[i].addEventListener('click', function(){
    // 				targetID = this.getAttribute('href');
    // 				document.querySelector(targetID).style.display = 'block';
    // 		});
    // }
  },
  methods: {
    selectValue(type, value) {
      if (type === 'service') {
        this.svcTypeCd = value
      } else if (type === 'type'){
        this.opnoinTypeCd = value
      }
    },
    // 공통코드 조회
		async getComCodeList() {
			let payload = {
				group_cd : 'CS'
			}
			await this.$store.dispatch('gigaoffice/getComCodeList', payload).then(res => {
				this.typeList = res.data.response.results
			})
			.catch(err => {
				console.log(err)
			})
		},
    //1-1. 토큰으로 사업자 번호 조회
    async getOwnerContactInfoByTokenId() {
      // let tokenId = sessionStorage.getItem('accessToken')
      //TODO TEST tokenId
      // let tokenId = '081d0f51f3f1a1d3e0a817e1951fc22c4eacc3c052f4849dd75aeb3be845407ce4bb060513d86b0baf36b86c95a0281d'
      let token_id = sessionStorage.getItem("accessToken");
      let param = { token_id: token_id };
      this.$store
        .dispatch("auth/getOwnerContactInfoByTokenId", param)
        .then(res => {
          // console.log(res.partyIdNumber1)
          this.ownerContactInfo = res
          //개인
          if (this.ownerContactInfo.partyIdtfNumberCd === "01") {
            this.partyIdNumber = this.ownerContactInfo.partyIdNumber1;
          } else {
            this.partyIdNumber = this.ownerContactInfo.partyIdNumber2;
          }
          this.partyName = this.ownerContactInfo.partyName
          this.partyIdNumber1 = this.ownerContactInfo.partyIdNumber1
          this.partyIdNumber2 = this.ownerContactInfo.partyIdNumber2
          this.mobileNumber = this.ownerContactInfo.mobileNumber
          this.phoneNumber = this.ownerContactInfo.phoneNumber
          this.custTelNum = this.mobileNumber != '' ? this.mobileNumber : this.phoneNumber
          this.custEmail = this.ownerContactInfo.email
          this.custEmail1 = this.custEmail.split('@')[0]
          this.custEmail2 = this.custEmail.split('@')[1]
          // this.getUserInfo(this.ownerContactInfo.partyIdNumber1);
        })
        .catch(error => {
          console.error(error);
        });
    },
    async postConsult() {
      if (!this.svcTypeCd) {
        alert("서비스 유형을 선택해주세요.")
        return false
      }
      if(!this.opnoinTypeCd) {
        alert("문의 유형을 선택해주세요.")
        return false
      }
      if(!this.custOpinion) {
        alert("문의 내용을 입력해주세요.")
        return false
      }
      if (!this.agreeCheck) {
        alert("개인정보 수집 및 이용에 동의해주세요.");
        return false;
      }
      if(!confirm('상담을 신청하시겠습니까?')) {
        return false
      }
      let payload = {
        token_id: sessionStorage.getItem("accessToken"),
        svc_type_cd: this.svcTypeCd,
        // this.custEmail
        // this.custTelNum
        // this.custNm
        // this.typeCd
        cust_opnion: this.custOpinion,
        opnoin_type_cd : this.opnoinTypeCd
        // this.agreeCheck
      }
      this.popupFlag = true;
      let response = await gigaofficeService.postConsult(payload);
      // console.log(response.data.code)
      // console.log(response.code)
      if (response.data.code == "100") {
        this.popupFlag = true;
      } else {
        alert("메일 발송이 실패하였습니다.");
      }
      this.popupFlag = true;
    }
  }
};
</script>
<style>
  .show { display:block !important; }
	.hide { display:none; }
</style>